<template>
    <div>
        <!-- <vue-header :title="type==1?'辅助决策报告':'养生报告'" isReturn="true" :rightName="type==1?'切换养生报告':'切换辅助决策报告'" :rightFun="()=>change({
            key:'type',
            value:type==1?2:1
        })" /> -->
        <vue-header title="辅助决策报告" isReturn="true"/>
        <div class="prescribe">
            <div class="prescribe-tion" v-if="type==1 && !health_load">
                <router-link :to="`/doctorReport/${items.report_token}?return=2`" class="prescribe-box flex" v-for="(items,indexs) in report_list"  v-bind:key="indexs">
                    <div class="flex">
                        <div class="prescribe-name">
                            健康状态：
                        </div>
                        <ul class="prescribe-orange">
                            <li>{{items.sick_text}}</li>
                        </ul>
                    </div>
                    <div class="prescribe-onClick flex flex-y">
                        <div class="prescribe-look flex-1">{{items.create_time}}</div>
                        <div class="prescribe-look">点击查看</div>
                        <van-icon name="arrow" />
                    </div>
                </router-link>
            </div>
            <div v-if="!health_load && report_list.length==0" class="no-report">您还没有辅助决策报告！</div>
            <div class="prescribe-tion" v-if="type==2 && !report_load">
                <router-link :to="`/report/${items.token}?return=2`" class="prescribe-box flex" v-for="(items,indexs) in report_list"  v-bind:key="indexs">
                    <div class="prescribe-name prescribe-start flex-1">健康状态：<span class="no-start">{{items.score}}</span></div>
                    <div class="flex flex-y">
                        <div class="prescribe-look flex-1">{{items.created_at}}</div>
                        <div class="prescribe-look">点击查看</div>
                        <van-icon name="arrow" />
                    </div>
                </router-link>
            </div>
            <div v-if="!report_load && report_list.length==0" class="no-report">您还没有养生报告！</div>
            <div v-if="report_list.length<total" class="flex flex-x flex-y user-loading"><van-loading /></div>
        </div>
    </div>
</template>

<script>
import { mapState  , mapActions , mapMutations } from 'vuex';
import vueHeader from '@/components/header';
let timer;
export default {
  name: 'list',
  components:{
    vueHeader
  },
  created:function(){
      this.axiosMain({
          user_id:this.$route.params.id,
          type:4
      });
      window.addEventListener('scroll', this.onSrollFun)
  },
  destroyed: function () {
      this.closeFun();
      window.removeEventListener('scroll', this.onSrollFun, false);
  },
  computed:{
      ...mapState('list',['report_list','type','health_load','report_load','total']),
  },
  methods:{
      back(){
          window.history.back(-1);
      },
      onSrollFun(){
            let _this = this;
            clearTimeout(timer);
            timer = setTimeout(()=>{
                let height = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight;
                //获取滚动条到底部的距离
                if(height<1000){
                    _this.axiosMain({
                        user_id:_this.$route.params.id,
                        type:4
                    });
                }
            },1000)
      },
      ...mapMutations('list',['change','closeFun']),
      ...mapActions('list',['axiosMain'])
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'list';
</style>
